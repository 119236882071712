/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
        $(window).on("load", function () {
          AOS.refresh();
        });
        AOS.init({
          duration: 1200,
          disable: "mobile",
        });

        var num = 125; //number of pixels before modifying styles
        $(window).bind("scroll", function () {
          if ($(window).scrollTop() > num) {
            $("body").addClass("body-scrolled");
          } else {
            $("body").removeClass("body-scrolled");
          }
        });

        $(".dropdown-menu").removeClass("slider-dropdown");
        $(".menu-side .bar-container").click(function () {
          $(".dropdown-menu").toggleClass("slider-dropdown");
          $(".holder-dropdown").toggleClass("show");
          $(this).toggleClass("open-dropdown");
        });

        $(".sub-arrow").click(function () {
          $(this).find("i").toggleClass("turn-arrow");
          $(this).siblings(".sub-pages").toggleClass("tap");
        });

        // SLICK HEROES
        $(".js-slick-heroes").slick({
          dots: true,
          infinite: false,
          arrows: false,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          fade: true,
          draggable: false,
          slidesToShow: 1,
          adaptiveHeight: true,
          pauseOnHover: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          },
          responsive: [
            {
              breakpoint: 500,
              settings: {
                dots: true,
                arrows: false,
              },
            },
          ],
        });

        if ($(".banner").hasClass("foundslide")) {
          $(".noslider").remove();
        } else if ($(".banner").hasClass("noslider")) {
          $(".js-slick-heroes").slick("unslick");
        }

        $(".js-loadmore-posts").click(function () {
          var button = $(this),
            data = {
              action: "loadmore",
              query: loadmore_posts_params.posts, // that's how we get params from wp_localize_script() function
              page: loadmore_posts_params.current_page,
            };

          $.ajax({
            url: loadmore_posts_params.ajaxurl, // AJAX handler
            data: data,
            type: "POST",
            beforeSend: function (xhr) {
              button.html('<span class="button primary">Loading...</span>'); // change the button text, you can also add a preloader image
            },
            success: function (data) {
              if (data) {
                button
                  .html('<span class="button primary">More posts</span>')
                  .before(data); // insert new posts
                loadmore_posts_params.current_page++;

                if (
                  loadmore_posts_params.current_page ===
                  loadmore_posts_params.max_page
                ) {
                  button.remove(); // if last page, remove the button
                }

                // you can also fire the "post-load" event here if you use a plugin that requires it
                // $( document.body ).trigger( 'post-load' );
              } else {
                button.remove(); // if no data, remove the button as well
              }
            },
          });
        });

        $(".js-select2").select2({
          minimumResultsForSearch: Infinity,
        });

        $(".js-select2-search").select2();

        $(".js-selector-with-url").change(function () {
          location.href = $(this).val();
        });

        $("#cat1").click(function () {
          $(".cat1").toggleClass("show-div");
          $(".cat2").removeClass("show-div");
          $(".cat3").removeClass("show-div");
          $("#arrow-cat1").toggleClass("fa-chevron-down");
          $("#arrow-cat1").toggleClass("fa-chevron-up");
          if ($("#arrow-cat2").hasClass("fa-chevron-up")) {
            $("#arrow-cat2").toggleClass("fa-chevron-down");
            $("#arrow-cat2").toggleClass("fa-chevron-up");
          }
          if ($("#arrow-cat3").hasClass("fa-chevron-up")) {
            $("#arrow-cat3").toggleClass("fa-chevron-down");
            $("#arrow-cat3").toggleClass("fa-chevron-up");
          }
        });
        $("#cat2").click(function () {
          $(".cat2").toggleClass("show-div");
          $(".cat1").removeClass("show-div");
          $(".cat3").removeClass("show-div");
          $("#arrow-cat2").toggleClass("fa-chevron-down");
          $("#arrow-cat2").toggleClass("fa-chevron-up");
          if ($("#arrow-cat1").hasClass("fa-chevron-up")) {
            $("#arrow-cat1").toggleClass("fa-chevron-down");
            $("#arrow-cat1").toggleClass("fa-chevron-up");
          }
          if ($("#arrow-cat3").hasClass("fa-chevron-up")) {
            $("#arrow-cat3").toggleClass("fa-chevron-down");
            $("#arrow-cat3").toggleClass("fa-chevron-up");
          }
        });
        $("#cat3").click(function () {
          $(".cat3").toggleClass("show-div");
          $(".cat1").removeClass("show-div");
          $(".cat2").removeClass("show-div");
          $("#arrow-cat3").toggleClass("fa-chevron-down");
          $("#arrow-cat3").toggleClass("fa-chevron-up");
          if ($("#arrow-cat2").hasClass("fa-chevron-up")) {
            $("#arrow-cat2").toggleClass("fa-chevron-down");
            $("#arrow-cat2").toggleClass("fa-chevron-up");
          }
          if ($("#arrow-cat1").hasClass("fa-chevron-up")) {
            $("#arrow-cat1").toggleClass("fa-chevron-down");
            $("#arrow-cat1").toggleClass("fa-chevron-up");
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        var $body = $("body");

        //Toggle Filter Options
        $(".js-filter-expand").click(function () {
          $(".js-filter-expander").toggleClass("expanded");
        });

        //Toggle menu
        $(".js-menu-open").click(function () {
          $("body").toggleClass("menu-off-canvas-active");
          $body.removeClass("fb-messenger-active");
          $body.removeClass("popup-active-feedback");
        });

        $(".js-menu-open-left").click(function () {
          $body.toggleClass("menu-off-canvas-active");
          $body.toggleClass("left");
          $body.removeClass("fb-messenger-active");
          $body.removeClass("popup-active-feedback");
        });

        //Toggle Feedback
        $(".js-popup-feedback").click(function () {
          $body.toggleClass("popup-active-feedback");
          $body.removeClass("fb-messenger-active");
        });

        //Toggle FB Messenger
        $(".js-fb-messenger").click(function () {
          $body.toggleClass("fb-messenger-active");
          $body.removeClass("menu-off-canvas-active");
        });

        //Toggle Search
        $(".js-popup-search").click(function (e) {
          e.preventDefault();
          $(".popup-wrap-search input").focus();
          $body.toggleClass("popup-active-search");
        });

        $(document).keyup(function (e) {
          if (e.keyCode === 27) {
            // escape key maps to keycode `27`
            $body.removeClass(
              "body-is-scrolled menu-off-canvas-active popup-active-search fb-messenger-active popup-active-feedback left"
            );
          }
        });

        //Toggle accordion links
        $(document).on("click", ".js-accordion-title", function (e) {
          e.preventDefault();
          $(this)
            .closest(".js-accordion")
            .toggleClass("expanded")
            .siblings()
            .removeClass("expanded");
        });

        $(".js-popup-feedback-form").submit(function (e) {
          e.preventDefault();

          var $this = $(this);
          var $message = $this.find(".js-message");

          $.ajax({
            type: "get",
            url: templateJS.ajaxURL,
            dataType: "json",
            data: {
              action: "popup_feedback_submit",
              data: $this.serialize(),
            },
            success: function (r) {
              if (r.success) {
                $this.find("input,textarea,button").prop("disabled", true);
                $message.text(
                  "Successful. This popup will automatically close in 5 seconds."
                );
                setTimeout(function () {
                  $body.toggleClass("popup-active-feedback");
                  $this.find("input,textarea,button").prop("disabled", false);
                  $this.find("input,textarea").val("");
                  $message.text(
                    "Please fill out the form below to leave feedback."
                  );
                }, 5000);
              } else {
                $message.hide("slow", function () {
                  $message.text(r.data.message);
                  $message.show("slow");
                });
              }
            },
          });
        });
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
        $(".js-slider-for").slick({
          dots: true,
          infinite: true,
          arrows: false,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          fade: true,
          draggable: false,
          adaptiveHeight: true,
          pauseOnHover: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          //prevArrow:'<span class="slick-prev"><i class="fa fa-angle-left"></i></span>',
          //nextArrow:'<span class="slick-next"><i class="fa fa-angle-right"></i></span>',

          customPaging: function (slider, i) {
            // return "<a>" + i + "</a><hr/><a>" + (i + 1) + "</a>";
          },
        });

        $(".dots").removeClass("active");
        $("#anchor-header").addClass("active");
        window.addEventListener("scroll", function () {
          var elementTarget = document.getElementById("section-featured-pages");
          var elementTargetWork = document.getElementById("section-workdiv");
          var elementTargetApply = document.getElementById("section-apply");
          var elementTargetLawyers = document.getElementById(
            "section-lawyers-div"
          );
          var elementTargetHeaders = document.getElementById("section-headers");
          if (
            window.scrollY <
            elementTargetHeaders.offsetTop +
              elementTargetHeaders.offsetHeight -
              300
          ) {
            $(".dots").removeClass("active");
            $("#anchor-header").addClass("active");
          }
          if (
            window.scrollY >
            elementTargetHeaders.offsetTop +
              elementTargetHeaders.offsetHeight -
              300
          ) {
            $(".dots").removeClass("active");
            $("#anchor-featured-pages").addClass("active");
          }
          if (
            window.scrollY >
            elementTargetWork.offsetTop + elementTargetWork.offsetHeight - 300
          ) {
            $(".dots").removeClass("active");
            $("#anchor-workdiv").addClass("active");
          }
          if (
            window.scrollY >
            elementTargetApply.offsetTop + elementTargetApply.offsetHeight - 300
          ) {
            $(".dots").removeClass("active");
            $("#anchor-apply").addClass("active");
          }
          if (
            window.scrollY >
            elementTargetLawyers.offsetTop +
              elementTargetLawyers.offsetHeight -
              300
          ) {
            $(".dots").removeClass("active");
            $("#anchor-lawyers").addClass("active");
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about: {
      init: function () {
        // JavaScript to be fired on the about us page
        $(".dots").removeClass("active");
        $("#anchor-header").addClass("active");
        window.addEventListener("scroll", function () {
          var elementTarget = document.getElementById("section-block-1");
          var elementTarget2 = document.getElementById("section-block-2");
          var elementTarget3 = document.getElementById("section-block-3");
          var elementTarget4 = document.getElementById("section-block-4");
          var elementTargetHeaders = document.getElementById("section-headers");
          if (
            window.scrollY <
            elementTargetHeaders.offsetTop +
              elementTargetHeaders.offsetHeight -
              300
          ) {
            $(".dots").removeClass("active");
            $("#anchor-header").addClass("active");
          }
          if (
            window.scrollY >
            elementTargetHeaders.offsetTop +
              elementTargetHeaders.offsetHeight -
              300
          ) {
            $(".dots").removeClass("active");
            $("#anchor-dot-1").addClass("active");
          }
          if (
            window.scrollY >
            elementTarget2.offsetTop + elementTarget2.offsetHeight - 300
          ) {
            $(".dots").removeClass("active");
            $("#anchor-dot-2").addClass("active");
          }
          if (
            window.scrollY >
            elementTarget3.offsetTop + elementTarget3.offsetHeight - 300
          ) {
            $(".dots").removeClass("active");
            $("#anchor-dot-3").addClass("active");
          }
          if (
            window.scrollY >
            elementTarget4.offsetTop + elementTarget4.offsetHeight - 300
          ) {
            $(".dots").removeClass("active");
            $("#anchor-dot-4").addClass("active");
          }
        });
      },
    },
    contact: {
      init: function () {
        // JavaScript to be fired on the contact page
        var gmap_id = $(".gmapcontact"),
          zoom = gmap_id.data("zoom") || 18,
          address = gmap_id.data("address") || "NL, Canada",
          geocoder = new google.maps.Geocoder();

        geocoder.geocode({ address: address }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            var centerLL = results[0].geometry.location;

            var map = new google.maps.Map(gmap_id.get(0), {
              center: centerLL,
              scrollwheel: false,
              zoom: zoom || 18,
            });
            new google.maps.Marker({
              map: map,
              position: centerLL,
              title: results[0].formatted_address,
            });
          } else {
            console.log(
              "Geocode was not successful for the following reason: " + status
            );
            $(".gmapcontact").html(
              '<div class="blurb no-results"><h3>Google Maps is not available due to unavailable location data.</h3></div>'
            );
          }
        });
      },
    },
    foundations_history: {
      init: function () {
        // JavaScript to be fired on the about us page

        $(".js-slider-for-history").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          prevArrow:
            '<span class="slick-prev"><i class="fa fa-angle-left"></i></span>',
          nextArrow:
            '<span class="slick-next"><i class="fa fa-angle-right"></i></span>',
          fade: true,
          asNavFor: ".js-slider-nav-history",
          adaptiveHeight: true,
        });

        $(".js-slider-nav-history").slick({
          slidesToShow: 7,
          slidesToScroll: 1,
          asNavFor: ".js-slider-for-history",
          dots: false,
          arrows: false,
          centerMode: true,
          draggable: true,
          focusOnSelect: true,
          adaptiveHeight: true,
          // infinite: false,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 7,
              },
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
              },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ],
        });

        $(".dots").removeClass("active");
        $("#anchor-header").addClass("active");
        window.addEventListener("scroll", function () {
          var elementTarget = document.getElementById("section-block-1");
          var elementTargetHeaders = document.getElementById("section-headers");
          if (
            window.scrollY <
            elementTargetHeaders.offsetTop +
              elementTargetHeaders.offsetHeight -
              300
          ) {
            $(".dots").removeClass("active");
            $("#anchor-header").addClass("active");
          }
          if (
            window.scrollY >
            elementTargetHeaders.offsetTop +
              elementTargetHeaders.offsetHeight -
              300
          ) {
            $(".dots").removeClass("active");
            $("#anchor-dot-1").addClass("active");
          }
        });
      },
    },
    mandate_priorities: {
      init: function () {
        // JavaScript to be fired on the about us page
        $(".dots").removeClass("active");
        $("#anchor-header").addClass("active");
        window.addEventListener("scroll", function () {
          var elementTarget = document.getElementById("section-block-1");
          var elementTargetHeaders = document.getElementById("section-headers");
          if (
            window.scrollY <
            elementTargetHeaders.offsetTop +
              elementTargetHeaders.offsetHeight -
              300
          ) {
            $(".dots").removeClass("active");
            $("#anchor-header").addClass("active");
          }
          if (
            window.scrollY >
            elementTargetHeaders.offsetTop +
              elementTargetHeaders.offsetHeight -
              300
          ) {
            $(".dots").removeClass("active");
            $("#anchor-dot-1").addClass("active");
          }
        });
      },
    },
    page_template_template_team: {
      init: function () {
        // JavaScript to be fired on the about us page
        $(".dots").removeClass("active");
        $("#anchor-header").addClass("active");
        window.addEventListener("scroll", function () {
          var elementTarget = document.getElementById("section-block-1");
          var elementTargetHeaders = document.getElementById("section-headers");
          if (
            window.scrollY <
            elementTargetHeaders.offsetTop +
              elementTargetHeaders.offsetHeight -
              420
          ) {
            $(".dots").removeClass("active");
            $("#anchor-header").addClass("active");
          } else {
            $(".dots").removeClass("active");
            $("#anchor-dot-1").addClass("active");
          }
        });
      },
    },
    page_template_template_grid: {
      init: function () {
        // JavaScript to be fired on the about us page
        $(".dots").removeClass("active");
        $("#anchor-header").addClass("active");
        window.addEventListener("scroll", function () {
          var elementTarget = document.getElementById("section-block-1");
          var elementTarget2 = document.getElementById("section-block-2");
          var elementTarget3 = document.getElementById("section-block-3");
          var elementTarget4 = document.getElementById("section-block-4");
          var elementTargetHeaders = document.getElementById("section-headers");
          if (
            window.scrollY <
            elementTargetHeaders.offsetTop +
              elementTargetHeaders.offsetHeight -
              300
          ) {
            $(".dots").removeClass("active");
            $("#anchor-header").addClass("active");
          }
          if (
            window.scrollY >
            elementTargetHeaders.offsetTop +
              elementTargetHeaders.offsetHeight -
              400
          ) {
            $(".dots").removeClass("active");
            $("#anchor-dot-1").addClass("active");
          }
          // if (
          //   window.scrollY >
          //   elementTarget2.offsetTop + elementTarget2.offsetHeight - 300
          // ) {
          //   $(".dots").removeClass("active");
          //   $("#anchor-dot-2").addClass("active");
          // }
          if (
            window.scrollY >
            elementTarget3.offsetTop + elementTarget3.offsetHeight - 100
          ) {
            $(".dots").removeClass("active");
            $("#anchor-dot-3").addClass("active");
          }
          if (
            window.scrollY >
            elementTarget4.offsetTop + elementTarget4.offsetHeight - 100
          ) {
            $(".dots").removeClass("active");
            $("#anchor-dot-4").addClass("active");
          }
        });
      },
    },
    page_template_template_work_grid: {
      init: function () {
        // JavaScript to be fired on the about us page
        $(".dots").removeClass("active");
        $("#anchor-header").addClass("active");
        window.addEventListener("scroll", function () {
          var elementTarget = document.getElementById("section-block-1");
          var elementTargetHeaders = document.getElementById("section-headers");
          if (
            window.scrollY <
            elementTargetHeaders.offsetTop +
              elementTargetHeaders.offsetHeight -
              420
          ) {
            $(".dots").removeClass("active");
            $("#anchor-header").addClass("active");
          } else {
            $(".dots").removeClass("active");
            $("#anchor-dot-1").addClass("active");
          }
        });
      },
    },
    page_template_template_grid_apply: {
      init: function () {
        // JavaScript to be fired on the about us page
        $(".dots").removeClass("active");
        $("#anchor-header").addClass("active");
        window.addEventListener("scroll", function () {
          var elementTarget = document.getElementById("section-block-1");
          var elementTargetHeaders = document.getElementById("section-headers");
          if (
            window.scrollY <
            elementTargetHeaders.offsetTop +
              elementTargetHeaders.offsetHeight -
              420
          ) {
            $(".dots").removeClass("active");
            $("#anchor-header").addClass("active");
          } else {
            $(".dots").removeClass("active");
            $("#anchor-dot-1").addClass("active");
          }
        });
      },
    },
    awards_scholarships: {
      init: function () {
        $(".js-slick-testimonials").slick({
          slidesToShow: 6,
          slidesToScroll: 1,
          // speed: 500,
          // autoplay: true,
          // autoplaySpeed: 5000,
          easing: "swing",
          // dots: false,
          // arrows: false,
          prevArrow:
            '<span class="slick-prev_gallery"><i class="fa fa-angle-left"></i></span>',
          nextArrow:
            '<span class="slick-next_gallery"><i class="fa fa-angle-right"></i></span>',
          centerMode: false,
          draggable: true,
          focusOnSelect: true,
          adaptiveHeight: true,
          pauseOnHover: true,
          infinite: true,
          dots: false,
          mobileFirst: true,
          responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 6,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 350,
              settings: {
                slidesToShow: 1,
              },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ],
        });
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
